<template>
  <div id="Home">
    <div
      v-for="(box, index) in HotList"
      :key="index + 'a'"
      style="margin-bottom: 0.5rem"
    >
      <div class="Title">
        <p>
          <i>{{ box.name }}</i>
        </p>
      </div>
      <div class="Blind_boxS">
        <div
          class="Blind_item"
          v-for="(item, index) in box.box"
          :key="index"
          @click="ToOpen(item.id)"
        >
        <div class="Blind_btn">
            <!-- <p>OPEN</p> -->
            <span
              ><img src="@/assets/images/public/Gold.png" alt="" />{{
                item.bean
              }}</span
            >
          </div>
        
          <div class="top">
            
            <div class="pic_w">
              <img :src="item.cover" alt="" />
            </div>
            <div class="pic_m">
              <img :src="item.weapon_cover" alt="" />
            </div>
          </div>
         <div class="Blind_name">{{ item.name }}</div>
         
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { Rooms, GetHotBox } from "@/network/api.js";
export default {
  name: "Home",
  data() {
    return {
      Headlines: [],
      HotList: [],
      BladeList: [],
      recruitList: [],
      vitalityList: [],
      classicList: [],
      soulList: [],
      XyList: [],
      jinqiu: [],
    };
  },
  filters: {
    StrIntercept(val) {
      return val.length > 9 ? val.substr(0, 9) + "..." : val;
    },
  },
  created() {
    this.GetRooms();
    this.ObtainHotBox();
  },
  methods: {
    //房间
    GetRooms() {
      Rooms("1", "1").then((res) => {
        // console.log(res.data.data.data)
        this.Headlines = res.data.data.data.slice(0, 2);
        // console.log(this.Headlines)
      });
    },
    //热门宝箱
    ObtainHotBox() {
      GetHotBox().then((res) => {
        this.HotList = res.data.data;
        this.jinqiu = res.data.data[0];
        this.HotList = this.HotList.filter((item) => {
          return item != this.jinqiu;
        });
        // this.HotList = res.data.data.hotboxlist.slice(0, 5)
        // this.BladeList = res.data.data.hotboxlist.slice(5, 20)
        // this.recruitList = res.data.data.hotboxlist.slice(20, 30)
        // this.vitalityList = res.data.data.hotboxlist.slice(30, 40)
        //this.classicList = res.data.data.hotboxlist.slice(35,40 )
        //this.soulList = res.data.data.hotboxlist.slice(40,45)
        //this.XyList = res.data.data.hotboxlist.slice(45,50)
      });
    },

    //路由跳转房间详情
    toRoom(id) {
      // console.log(id)
      this.$router.push({
        path: "/room",
        query: {
          id: id,
        },
      });
    },

    //路由跳转开箱
    ToOpen(id) {
      this.$router.push({
        path: "/Open",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
  
  <style lang="scss">
.van-swipe-item {
  background: none !important;
}
#Home {
  width: 100%;
  /*padding:10px;*/
  box-sizing: border-box;
  .my-swipe {
    // background: url("../../assets/images/q2/home/swiperbac.png") no-repeat
    //   center;
    // background-size: 100% 100%;
    // padding-top: 45px;
    // padding-bottom: 15px;
    img {
      width: 95%;
    }
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 1px;
    text-align: center;
  }
  .rooms {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .rooms_item {
      width: 49%;
      padding: 0 4px 6px;
      box-sizing: border-box;
      border-radius: 8px;
      background: url(../../assets/images/rooms/roll_item.png) no-repeat center;
      background-size: 100% 100%;
      &:last-child {
        background: url(../../assets/images/rooms/roll_item2.png) no-repeat
          center;
        background-size: 100% 100%;
      }
      .top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .pic {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .room_data {
          width: calc(100% - 40px);
          .room_title {
            width: 100%;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
          }
          .room_price {
            width: 100%;
            height: 20px;
            line-height: 20px;
            color: #f4f4f4;
            display: flex;
            span {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: bold;
              color: #f1bc60;
              img {
                width: 18px;
              }
            }
          }
        }
      }

      .center {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        .pic {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size: 90% 90% !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          img {
            max-width: 110%;
            max-height: 110%;
          }
        }
      }
      .countDown {
        width: 100%;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        .colon {
          display: inline-block;
          width: 20px;
          height: 20px;
          text-align: center;
          color: #fff;
          font-size: 12px;
        }

        .block {
          display: inline-block;
          width: 30px;
          height: 20px;
          background: rgba(48, 57, 81, 0.8);
          border-top: 1px solid #49587e;
          color: #fff;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.4em;
          padding-left: 5px;
          text-align: center;
          background-size: cover;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }
    }
  }
  .Title::before {
    background: none;
  }
  .Title {
    font-family: ninefont;
    font-size: 0.8rem;
    margin-top: 20px;
    background: url('../../assets/images/985/box/title.png') no-repeat center;
    background-size: 130% 20%;
    p{
      color: #fff !important;
    }
    // background-position: 0px 10px;
  }
  .Title::after {
    background: none;
  }

  .Blind_boxS {
    width: 100%;
    flex: 1;
    justify-content: space-between;
    flex-wrap: wrap;
    .Blind_item {
      margin-top: 10px;
      width: 48%;
      padding-bottom: 15px;
      display: inline-block;
      // margin-left: 6%;
      background: url('../../assets/images/985/box/boxbac.png') no-repeat center;
      background-size: 100% 100%;
      position: relative;
      margin-left: 1%;
      .top {
        width: 100%;
        height: 120px;
        position: relative;
        .pic_w {
          position: absolute;
          left: 0;
          top: 5%;
          width: 100%;
          height: 110px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;

          img {
            max-width: 60%;
            max-height: 60%;
            vertical-align: middle;
          }
        }
        .pic_m {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
          }
        }
      }
      .Blind_name {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        font-family: numfont !important;
      }
      .Blind_btn {
        /*background-image: linear-gradient(#ffcf00,#ffb500);*/
        width: 100px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: numfont !important;
        margin-top: 30px;
        justify-content: flex-end;
        p {
          width: 50%;
          height: 20px;
          border-right: 1px solid #000;
          text-align: center;
          line-height: 20px;
          font-size: 14px;
          font-weight: 550;
          color: #fff;
        }
        span {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fbfa02;
          font-size: 0.42667rem;
          font-weight: 700;
          img {
            width: 15px;
            padding: 3px;
          }
        }
      }
    }
  }
}
</style>
  